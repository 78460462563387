.container {
  &--bg-contain {
    .cmp-container {
      height: 100dvh;
      max-height: 1200px;
      background-position: center;
    }
    &-left {
      @media (max-width: $breakpoint-mobile) {
      .cmp-container {
        background-position: 33%;
      }
    }
  }
  }
  &.move-up {
    position: relative;
    margin-top: -5em;
  }
  &.center {
    .cmp-container {
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &.right {
    .cmp-container {
      div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  &.bg-white {
    background-color: white;
  }
  &.bg-gold {
    background-color: #b8a880;
  }
}