.cmp-image {
  margin-top: 2.25em;
}
.cmp-image__link {
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}

.image {
  &.small {
    max-width: 50px;
    padding: 5px;
    @media (max-width: $breakpoint-mobile) {
      img {
        min-width: 45px;
      }
    }
    .cmp-image {
      margin: 0;
    }
  }
}
