//== XF Footer style, used on page template

#main-footer {
  margin-top: 5em;
  padding: 1.5em;
  border-top: 0.125em solid $color-shade-1;
  background-color: #0d0d0d;
  color: white;
  max-width: calc(100vw - 3rem);

  .cmp-text {
    color: white;
  }

  ul {
    li {
      line-height: 1.5;
      a {
        color: white;
        text-decoration: underline;
      }
      &::marker {
        content: '';
      }
    }
  }

  .separator {
    display: none;
  }

  & .cmp-container {
    display: flex;
  }

  .image,
  .text {
    margin: 0 1em;
  }

  & .cmp-image {
    margin: 0;
  }

  .cmp-image__image {
    display: block;
    width: 9.2em;
    margin: 0 auto;
  }

  .cmp-title {
    margin: 0.5em 1em;
    text-align: center;
    @media only screen and (max-width: 768px) {
      margin: 1em 1em;
    }
  }

  .cmp-text {
    margin: 0;
    text-align: center;
    & p {
      margin-block: 0;
      & a {
        color: $color-accent;
        text-decoration: none;
        &:focus {
          color: $color-accent-lighter;
          border: 0.125em solid $color-accent-lighter;
          border-radius: 0.25em;
        }
        &:hover {
          color: $color-accent-darker;
        }
      }
    }
  }
}
