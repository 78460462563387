
//== Defaults

html, body {
  margin: 0;
  color: $color-text;
  font-size: $font-size;
  font-family: $font-family;
  line-height: $font-height;
  background: $color-background;
  overflow-x: hidden;
}

a {
  color: $color-link;
}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

.cmp-spacer--small {
  height: 1.875rem;
}

@media(min-width:48em) {
  .cmp-spacer--small {
    height: 3.75rem;
  }
}

.cmp-spacer--large {
  height: 1.875rem;
}

@media(min-width:48em) {
  .cmp-spacer--large {
    height: 3.75rem;
  }
}

@media(min-width:62em) {
  .cmp-spacer--large {
    height: 5.625rem;
  }

  /*End Spacer*/

  /*Full Width*/
  .container.container--full-width > .cmp-container {
    max-width: 100%
  }

  /*End Full Width*/

  /*No Gutter*/
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }

  .container.container--no-gutters > .cmp-container {
    padding-left: 0;
    padding-right: 0;
  }

  .container.container--no-gutters > .cmp-container > .aem-Grid {
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }

  .container.container--no-gutters > .cmp-container > .aem-Grid > div[class*=" aem-GridColumn"],
  .container.container--no-gutters > .cmp-container > .aem-Grid > div[class^=aem-GridColumn] {
    padding-left: 0;
    padding-right: 0;
  }
}