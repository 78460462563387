.cmp-title {
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
}
.cmp-title__text {
  margin: 0;
}
.cmp-title__link {
  color: $color-accent;
  text-decoration: none;
  border: 0.125rem solid $color-transparent;
  border-radius: 0.25rem;
  &:hover {
    color: $color-accent-darker;
    text-decoration: underline;
    text-decoration-color: $color-accent-darker;
    text-decoration-thickness: 0.125rem;
  }
  &:focus {
    color: $color-accent;
    text-decoration: underline;
    border-color: $color-accent-lighter;
    outline: none;
    text-decoration-color: $color-accent-lighter;
    text-decoration-thickness: 0.125rem;
  }
}

.title {
  &.center {
    .cmp-title__text {
        text-align: center;
      }
  }
  &.right {
    .cmp-title__text {
        text-align: right;
      }
  }
  &.font-gold {
    .cmp-title__text {
      color: $color-gold;
    }
  }
  &.no-margin {
    .cmp-title {
      margin: 0;
    }
  }
}
