.h1_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: bold;
    font-size: 3em;
    font-family: $font-family;
    line-height: 4.125rem;
    @media (max-width: $breakpoint-mobile) {
      line-height: 1;
      font-size: 1.5em;
    }
  }
}

.h2_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: bold;
    font-size: 2.25em;
    font-family: $font-family;
    line-height: 3.0625rem;
    @media (max-width: $breakpoint-mobile) {
      line-height: 1;
      font-size: 1.5em;
    }
  }
}

.h3_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: 1.75em;
    line-height: 2.25rem;
  }
  .cmp-title__link {
  }
}

.h4_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: bold;
    font-size: 1.125em;
  }
  .cmp-title__link {
  }
}

.h5_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: 1em;
  }
  .cmp-title__link {
  }
}

.h6_style {
  .cmp-title {
  }
  .cmp-title__text {
    color: $color-shade-3;
    font-weight: normal;
    font-size: 0.625em;
    text-transform: uppercase;
  }
  .cmp-title__link {
  }
}
