.cmp-htmlblock{

    .stylelink {
      text-align: center;
      padding-bottom: 40px;
      margin-bottom: 40px;
      position: relative;
      z-index: 2;
      .no-border {
        border: none !important;
        cursor: pointer;
      }
      div {
        font-family: 'Sinkin Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
        font-weight: 600;
        font-style: normal;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 1.75px;
        margin: 5px 12px;
      }
      .ot-sdk-show-settings {
        white-space: nowrap;
        border-color: transparent !important;
        background-color: transparent;
        margin: 2rem 0 0 0 ;
        text-decoration: none;
        transition: all .25s ease-in-out;
        color: #eceff1 !important;
        cursor: pointer;
      }
      #ot-sdk-btn.ot-sdk-show-settings:hover, #ot-sdk-btn.optanon-show-settings:hover {
        background-color: transparent !important;
      }
    }
  }