
//== Font loading

@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: '#{$name}';
        font-style: $style;
        src: url('resources/fonts/#{$file}.ttf') format('truetype');
    }
}

@import url("https://p.typekit.net/p.css?s=1&k=gog6ltf&ht=tk&f=39200.39201.39202.39203&a=158126504&app=typekit&e=css");

@font-face {
    font-family: "century-gothic";
    src: url("https://use.typekit.net/af/40bdad/00000000000000007735ba11/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/40bdad/00000000000000007735ba11/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/40bdad/00000000000000007735ba11/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "century-gothic";
    src: url("https://use.typekit.net/af/0f9780/00000000000000007735ba15/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/0f9780/00000000000000007735ba15/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/0f9780/00000000000000007735ba15/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "century-gothic";
    src: url("https://use.typekit.net/af/9e55b6/00000000000000007735ba19/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/9e55b6/00000000000000007735ba19/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/9e55b6/00000000000000007735ba19/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "century-gothic";
    src: url("https://use.typekit.net/af/78c6b9/00000000000000007735ba1f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/78c6b9/00000000000000007735ba1f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/78c6b9/00000000000000007735ba1f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

.tk-century-gothic {
    font-family: "century-gothic", sans-serif;
}

$font-family: "century-gothic", sans-serif;
